import axios from 'axios'

export default () => {
  return axios.create({
    baseURL: 'https://api.encontreempresas.com/',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJ0aW1lIjoxNTk2MTE5MTA5MzYxLCJhcHBsaWNhdGlvbl9pZCI6MX0.Oe5JzEiH_-A-KNyOYfmq1ypYIOwuoNEEZmTgJiC3JXo`,
    },
  })
}
