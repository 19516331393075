import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import parse from 'html-react-parser'

class WhyChooseUs extends Component {
  render() {
    let publicUrl = process.env.PUBLIC_URL + '/'
    let imagealt = 'image'

    return (
      <section className="service-one go-top pb-5" id="servicos">
        <div className="container">
          <div className="block-title text-center">
            <p>
              <span>Porque nos escolher</span>
            </p>
            <h3>
              Cresça o seu negócio desfrutando <br /> dos nossos serviços
            </h3>
            <div className="block-title__line" />
            {/* /.block-title__line */}
          </div>
          {/* /.block-title */}
          <div className="row high-gutter  go-top" style={{ marginTop: -20 }}>
            <div className="col-lg-6 mt-5">
              <div className="service-one__single">
                <div className="service-one__top">
                  <div className="service-one__icon">
                    {/* <img src={publicUrl + 'assets/images/shapes/service-i-1.png'} alt={imagealt} /> */}
                    <i className="fas fa-laptop-code"></i>
                  </div>
                  {/* /.service-one__icon */}
                  <div className="service-one__top-content">
                    <h3>
                      {/* <Link to="/service-details">Business Strategy &amp; Market Research</Link> */}
                      Criação de sites e landing pages
                    </h3>
                    <p>Desenvolvimento web</p>
                  </div>
                  {/* /.service-one__top-content */}
                </div>
                {/* /.service-one__top */}
                <p>Precisa de um site ou landing page que converta? Não se preocupe. Criaremos para você!</p>
              </div>
              {/* /.service-one__single */}
            </div>
            {/* /.col-lg-6 mt-5 */}
            <div className="col-lg-6 mt-5">
              <div className="service-one__single">
                <div className="service-one__top">
                  <div className="service-one__icon">
                    {/* <img src={publicUrl + 'assets/images/shapes/service-i-2.png'} alt={imagealt} /> */}
                    <i className="fas fa-mobile-alt"></i>
                  </div>
                  {/* /.service-one__icon */}
                  <div className="service-one__top-content">
                    <h3>
                      {/* <Link to="/service-details">Criação de aplicativos híbridos</Link> */}
                      Criação de aplicativos híbridos
                    </h3>
                    <p>Desenvolvimento mobile</p>
                  </div>
                  {/* /.service-one__top-content */}
                </div>
                {/* /.service-one__top */}
                <p>Aplicativos para híbridos para Android e IOS. Com os aplicativos híbridos você compra 2 pelo preço de 1!</p>
              </div>
              {/* /.service-one__single */}
            </div>
            {/* /.col-lg-6 mt-5 */}
            <div className="col-lg-6 mt-5">
              <div className="service-one__single">
                <div className="service-one__top">
                  <div className="service-one__icon">
                    {/* <img src={publicUrl + 'assets/images/shapes/service-i-3.png'} alt={imagealt} /> */}
                    <i className="fas fa-pencil-ruler"></i>
                  </div>
                  {/* /.service-one__icon */}
                  <div className="service-one__top-content">
                    <h3>
                      {/* <Link to="/service-details">Web Design And Development</Link> */}
                      Sistemas e automação de processos
                    </h3>
                    <p>Desenvolvimento</p>
                  </div>
                  {/* /.service-one__top-content */}
                </div>
                {/* /.service-one__top */}
                <p>Precisando de soluções para automatizar e aprimoras os processos e tarefas da sua empresa? Criamos um sistema para você!</p>
              </div>
              {/* /.service-one__single */}
            </div>
          </div>
          {/* /.row */}
        </div>
        {/* /.container */}
      </section>
    )
  }
}

export default WhyChooseUs
