import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import parse from 'html-react-parser'

class BrandOne extends Component {
  // componentDidMount() {
  //   const $ = window.$
  //   if ($('.thm__owl-carousel').length) {
  //     $('.thm__owl-carousel').each(function () {
  //       var Self = $(this)
  //       var carouselOptions = Self.data('options')
  //       var carouselPrevSelector = Self.data('carousel-prev-btn')
  //       var carouselNextSelector = Self.data('carousel-next-btn')
  //       var thmCarousel = Self.owlCarousel(carouselOptions)
  //       if (carouselPrevSelector !== undefined) {
  //         $(carouselPrevSelector).on('click', function () {
  //           thmCarousel.trigger('prev.owl.carousel')
  //           return false
  //         })
  //       }
  //       if (carouselNextSelector !== undefined) {
  //         $(carouselNextSelector).on('click', function () {
  //           thmCarousel.trigger('next.owl.carousel')
  //           return false
  //         })
  //       }
  //     })
  //   }
  // }

  render() {
    let publicUrl = process.env.PUBLIC_URL + '/'
    let imagealt = 'image'

    return (
      <section className="brand-one">
        <div className="block-title text-center">
          <p>
            <span>Confiam em nosso trabalho</span>
          </p>
          {/* /.block-title__line */}
        </div>
        <div className="container">
          <div
            // className="brand-one__carousel owl-carousel owl-theme thm__owl-carousel"
            className="brand-one__carousel "
            // data-options='{"loop": true, "autoplay": true, "autoplayTimeout": 5000, "items": 6, "margin": 150, "smartSpeed": 700, "nav": false, "dots": false, "responsive": { "0": { "items": 2, "margin": 15 },"575": { "items": 3, "margin": 30 },"767": { "items": 3, "margin": 30 }, "991": { "items": 4, "margin": 30 }, "1199": { "items": 4, "margin": 75 }, "1200": { "items": 6 } }}'
          >
            <div className="item">
              <img src={publicUrl + 'assets/images/brand/logo-fasters.png'} alt={imagealt} />
            </div>
          </div>
        </div>
      </section>
    )
  }
}

export default BrandOne
