import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import parse from 'html-react-parser'
import { scrollTo } from '../../utils'
class Banner extends Component {
  render() {
    let publicUrl = process.env.PUBLIC_URL + '/'
    let imagealt = 'image'

    return (
      <section className="banner-one go-top" style={{ backgroundImage: 'url(' + publicUrl + 'assets/images/backgrounds/banner-bg-1-1.png)' }}>
        <img src={publicUrl + 'assets/images/resources/banner-1-1.png'} alt={imagealt} className="banner-one__moc" />
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-6">
              <div className="banner-one__content">
                <h2>
                  <span>A solução que </span> <br /> faltava para o seu negócio.
                </h2>
                <p>
                  Precisa de uma solução para site, sistema ou aplicativo? <br /> <strong> Nós resolvemos!</strong>
                </p>
                {/* <Link to="/service" className="thm-btn banner-one__btn"> */}
                <a href="#contato" onClick={(e) => scrollTo('contato', e)} className="thm-btn banner-one__btn">
                  Fale Conosco <i className="fa fa-angle-double-right" />
                </a>
                {/* </Link> */}
                {/* /.thm-btn */}
              </div>
            </div>
          </div>
        </div>
      </section>
    )
  }
}

export default Banner
