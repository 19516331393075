import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import parse from 'html-react-parser'
import { scrollTo } from '../../utils'
class AboutStyleOne extends Component {
  render() {
    let publicUrl = process.env.PUBLIC_URL + '/'
    let imagealt = 'image'

    return (
      <section className="about-one  go-top pb-5" id="sobre-nos">
        <div className="container pb-5">
          <img src={publicUrl + 'assets/images/resources/cta-1-1.png'} alt={imagealt} className="about-one__moc" />
          <div className="row justify-content-end">
            <div className="col-lg-6">
              <div className="about-one__content">
                <div className="block-title text-left">
                  <p>
                    <span>Sobre nós</span>
                  </p>
                  <h3>Somos especialistas em soluções digitais</h3>
                  <div className="block-title__line" />
                  {/* /.block-title__line */}
                </div>
                {/* /.block-title */}
                <p>
                  Somos mais que uma <strong>software house</strong>, nosso objetivo é trazer conforto e soluções para os nossos clientes
                  compartilhando nossa experiência auxiliando-os no seu processo de transformação digital.
                </p>
                <br />
                <p>
                  Temos mais de <strong>4 anos de experiência</strong> solucionando os mais diversos desafios no desenvolvimento de{' '}
                  <strong>Sites institucionais</strong> para pequenas e médias empresas, <strong>aplicativos</strong> para empresas dos mais diversos
                  tamanhos,
                  <strong>lojas virtuais</strong> e <strong>sistemas</strong> para grandes empresas do <strong>mercado financeiro</strong>.
                </p>
                <h4>Nosso modelo de trabalho</h4>
                <p>
                  Na <strong>Dition</strong> trabalhamos com
                  <strong> metodologia ágil</strong>. Um conjunto de práticas que visam permitir, por meio de inspeção e adaptações frequentes,
                  entregas rápidas, com qualidade e alinhadas à necessidade do cliente e da empresa.
                </p>

                {/* <Link to="/about" className="thm-btn about-one__btn"> */}
                <a href="#contato" onClick={(e) => scrollTo('contato', e)} className="thm-btn about-one__btn">
                  Faça um orçamento <i className="fa fa-angle-double-right" />
                </a>
                {/* </Link> */}
                {/* /.thm-btn */}
              </div>
              {/* /.about-one__content */}
            </div>
            {/* /.col-lg-6 */}
          </div>
          {/* /.row */}
        </div>
        {/* /.container */}
      </section>
    )
  }
}

export default AboutStyleOne
