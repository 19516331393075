import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import parse from 'html-react-parser'
import api from '../../api'
class Contact extends Component {
  constructor(props) {
    super(props)
    this.state = { loading: false }
  }

  sendContactEmail = (data) => {
    return new Promise((resolve, reject) => {
      api()
        .post(`/api/emails/contact`, data)
        .then((response) => {
          resolve(response.data)
        })
        .catch((error) => {
          console.log(error.message)
          reject({
            status: error.response?.status || error.response?.data?.status,
            msg: error.response?.data?.msg || 'Ocorreu um erro ao tentar enviar sua mensagem, por favor entre em contato via whatsapp',
          })
        })
    })
  }

  submit = async (e) => {
    try {
      this.setState({ loading: true })
      e.preventDefault()
      var formData = new FormData(e.target)

      var res = await this.sendContactEmail({ ...Object.fromEntries(formData), subject: 'Orçamento' })
      this.setState({ loading: false })

      alert('Mensagem enviada com sucesso')
    } catch (error) {
      console.log(error)
      alert(error.msg)
    }
  }

  render() {
    let publicUrl = process.env.PUBLIC_URL + '/'

    return (
      <section id="contato" className="contact-one pt-0">
        <div className="container">
          <div className="block-title-two text-center">
            <p>Fale Conosco</p>
            <h3>
              Não perca mais tempo <br /> fale conosco!
            </h3>
          </div>
          {/* /.block-title-two */}
          <div className="row">
            {/* <div className="col-lg-5">
              <div className="contact-one__box" style={{ backgroundImage: 'url(' + publicUrl + 'assets/images/resources/contact-1-1.jpg)' }}>
                <div className="contact-one__box-inner">
                  <h3>
                    Do You Have <br /> Any Questions?
                  </h3>
                  <p>But we ipsum dolor sit amet consectetur adeisicing elit, sed do eiusmod tncididunt ut labore et dolore magna aliqua</p>
                  <a href="#" className="thm-btn contact-one__box-btn">
                    Contact Us <i className="fa fa-angle-double-right" />
                  </a>
                </div>
              </div>
            </div> */}
            <div className="col-lg-5">
              <div className="social">
                <a
                  target="_blank"
                  className="whatsapp"
                  href="https://api.whatsapp.com/send?phone=5511943544238&text=Ol%C3%A1%2C%20gostaria%20de%20fazer%20um%20or%C3%A7amento!"
                >
                  <i className="fab fa-whatsapp"></i> Whatsapp
                </a>
                {/* <a target="_blank" className="facebook" href="https://www.facebook.com/encontreempresas">
                  <i className="fab fa-facebook-f"></i> Facebook
                </a> */}
                <a target="_blank" className="instagram" href="https://www.instagram.com/ditionbr/">
                  <i className="fab fa-instagram"></i> Instagram
                </a>
                <a target="_blank" className="linkedin" href="https://www.linkedin.com/company/ditionbr/">
                  <i className="fab fa-linkedin-in"></i> Linkedin
                </a>
              </div>
            </div>
            {/* /.col-lg-5 */}
            <div className="col-lg-7 d-flex">
              <div className="my-auto">
                <ul className="list-unstyled contact-two__info-list">
                  <li>
                    <div className="contact-two__info-list-title">
                      <i className="far fa-phone" />
                      Telefone :
                    </div>
                    {/* /.contact-two__info-list-title */}
                    <p>
                      <a href="tel:11943544238"> (11) 9 4354-4238</a>
                    </p>
                  </li>
                  <li>
                    <div className="contact-two__info-list-title">
                      <i className="far fa-envelope" />
                      Email:
                    </div>
                    <p>
                      <a href="mailto:jefferson@dition.com.br"> jefferson@dition.com.br</a>
                    </p>
                  </li>
                </ul>
                {/* <form onSubmit={this.submit.bind(this)} action="#" className="contact-one__form">
                  <h3>Por favor preencha o formulário</h3>
                  <p>Diga-nos como podemos te ajudar e daremos um retorno em breve!</p>
                  <div className="row">
                    <div className="col-lg-6">
                      <div className="contact-one__input-group">
                        <i className="contact-one__input-icon far fa-user" />
                        <input maxLength={50} type="text" required name="name" placeholder="Seu nome" />
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div className="contact-one__input-group">
                        <i className="contact-one__input-icon far fa-envelope" />
                        <input maxLength={50} type="email" required name="email_from" placeholder="Seu E-mail" />
                      </div>
                    </div>
                    <div className="col-lg-6">
                       <div className="contact-one__input-group">
                        <select className="selectpicker">
                          <option value>Your Subject</option>
                          <option value="#">Query For Pricing</option>
                          <option value>Query For Busniess</option>
                        </select>
                      </div> *
                      <div className="contact-one__input-group">
                        <i class="contact-one__input-icon far fa-building"></i>
                        <input type="text" maxLength={50} required name="company" placeholder="Nome da sua empresa" />
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div className="contact-one__input-group">
                        <i className="contact-one__input-icon far fa-phone" />
                        <input type="phone" maxLength={20} required name="phone" placeholder="Seu telefone" />
                      </div>
                    </div>
                    <div className="col-lg-12">
                      <div className="contact-one__input-group">
                        <i className="contact-one__input-icon far fa-pencil-alt" />
                        <textarea name="message" maxLength={1000} required placeholder="Mensagem" defaultValue={''} />
                      </div>
                    </div>
                    <div className="col-lg-12">
                      <button disabled={this.state.loading} type="submit" className="thm-btn contact-one__form-btn">
                        {this.state.loading ? (
                          <div className="spinner-border spinner-border-sm" role="status">
                            <span className="sr-only">Loading...</span>
                          </div>
                        ) : (
                          <>
                            Enviar <i className="fa fa-angle-double-right" />
                          </>
                        )}
                      </button>
                    </div>
                  </div>
                </form> */}
                {/* /.contact-one__form */}
              </div>
              {/* /.my-auto */}
            </div>
            {/* /.col-lg-7 */}
          </div>
          {/* /.row */}
        </div>
        {/* /.container */}
      </section>
    )
  }
}

export default Contact
