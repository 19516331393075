import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import { scrollTo } from '../../utils'
class Navbar extends Component {
  render() {
    let publicUrl = process.env.PUBLIC_URL + '/'
    let imgattr = 'Logo dition solucoes em tecnologia ltda'
    let anchor = '#'
    return (
      <header className="site-header-one go-top">
        <nav className="main-nav__one stricky">
          <div className="container-fluid">
            <div className="main-nav__logo-box">
              {this.props.h1 ? (
                <h1>
                  <Link to="/">
                    <img src={publicUrl + 'assets/images/logo-dition-white.png'} alt={imgattr} />
                  </Link>
                </h1>
              ) : (
                <Link to="/">
                  <img src={publicUrl + 'assets/images/logo-dition-white.png'} alt={imgattr} />
                </Link>
              )}
              <a href="#" className="side-menu__toggler">
                <i className="fa fa-bars" />
              </a>
            </div>
            {/* /.main-nav__logo-box */}
            <div className="main-nav__main-navigation">
              <ul className=" main-nav__navigation-box">
                {/* <li className="dropdown">
                  <Link to="/">Home</Link>
                  <ul>
                    <li>
                      <Link to="/home-v2">Home 02</Link>
                    </li>
                    <li>
                      <Link to="/home-v3">Home 03</Link>
                    </li>
                    <li>
                      <Link to="/home-v4">Home 04</Link>
                    </li>
                  </ul>
                </li> */}
                {/* <li>
                  <Link to="/about">About</Link>
                </li> */}
                <li>
                  <a href="#servicos" onClick={(e) => scrollTo('servicos', e)}>
                    Serviços
                  </a>
                </li>
                <li>
                  <a href="#sobre-nos" onClick={(e) => scrollTo('sobre-nos', e)}>
                    Sobre nós
                  </a>
                </li>
                <li>
                  <a href="#contato" onClick={(e) => scrollTo('contato', e)}>
                    Contato
                  </a>
                </li>
                {/* <li className="dropdown">
                  <a href="#">Services</a>
                  <ul>
                    <li>
                      <Link to="/service">Services 01</Link>
                    </li>
                    <li>
                      <Link to="/service-v2">Services 02</Link>
                    </li>
                    <li>
                      <Link to="/service-details">Services Details</Link>
                    </li>
                  </ul>
                </li> */}
                {/* <li className="dropdown">
                  <a href="#">Work</a>
                  <ul>
                    <li>
                      <Link to="/portfolio-standard">Portfolio Standard</Link>
                    </li>
                    <li>
                      <Link to="/portfolio-full">Portfolio Full</Link>
                    </li>
                    <li>
                      <Link to="/portfolio-masonary">Portfolio Masonary</Link>
                    </li>
                    <li>
                      <Link to="/portfolio-details">Portfolio Details</Link>
                    </li>
                  </ul>
                </li> */}
                {/* <li className="dropdown">
                  <a href="#">Pages</a>
                  <ul>
                    <li>
                      <Link to="/pricing">Pricing</Link>
                    </li>
                    <li>
                      <Link to="/faq">FAQ</Link>
                    </li>
                    <li>
                      <Link to="/testimonial">Testimonials</Link>
                    </li>
                    <li>
                      <Link to="/team">Team</Link>
                    </li>
                  </ul>
                </li> */}
                {/* <li className="dropdown">
                  <Link to="/blog-standard">News</Link>
                  <ul>
                    <li>
                      <Link to="/blog-grid">News Page</Link>
                    </li>
                    <li>
                      <Link to="/blog-list">News List</Link>
                    </li>
                    <li>
                      <Link to="/blog-details">News Details</Link>
                    </li>
                  </ul>
                </li> */}
                {/* <li>
                  <Link to="/contact">Contact</Link>
                </li> */}
              </ul>
            </div>
            {/* /.main-nav__main-navigation */}
            <div className="main-nav__right">
              {/* <Link to="/contact" className="thm-btn"> */}
              <a href="#contato" className="thm-btn" onClick={(e) => scrollTo('contato', e)}>
                Fale Conosco <i className="fa fa-angle-right" />
              </a>
              {/* </Link> */}
              {/* /.thm-btn */}
            </div>
            {/* /.main-nav__right */}
          </div>
        </nav>
      </header>
    )
  }
}

export default Navbar
