import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import parse from 'html-react-parser'

class PortfolioONe extends Component {
  render() {
    let publicUrl = process.env.PUBLIC_URL + '/'
    let imagealt = 'image'

    return (
      <section className="portfolio-one go-top">
        <div className="container">
          <div className="block-title text-center">
            <p>
              <span>Projetos</span>
            </p>
            <h3>Veja os nossos projetos principais</h3>
            <div className="block-title__line" />
            {/* /.block-title__line */}
          </div>
          {/* /.block-title */}
          <div className="row">
            <div className="col-lg-4 mx-auto">
              <div className="portfolio-one__single">
                <img src={publicUrl + 'assets/images/resources/logo-encontre-empresas.png'} alt={imagealt} />
                <div className="portfolio-one__content">
                  <div className="portfolio-one__content-inner">
                    <h3>
                      <a target="_blank" href="https://encontreempresas.com/">
                        Portal Encontre Empresas
                      </a>
                      {/* <Link to="/portfolio-details">Creative Arts</Link> */}
                    </h3>
                    <p>Design, Site e Sistema</p>
                  </div>
                  {/* /.portfolio-one__content-inner */}
                </div>
                {/* /.portfolio-one__content */}
              </div>
              {/* /.portfolio-one__single */}
            </div>
          </div>
          {/* /.row */}
          <div className="portfolio-one__more-btn__block text-center">
            {/* <Link to="/portfolio-full" className="thm-btn portfolio-one__more-btn">
              View More <i className="fa fa-angle-double-right" />
            </Link> */}
            {/* /.thm-btn */}
          </div>
          {/* /.portfolio-one__more-btn__block */}
        </div>
        {/* /.container */}
      </section>
    )
  }
}

export default PortfolioONe
